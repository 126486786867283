import React from "react";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import NotesA from "./components/notes-a";
import NotesB from "./components/notes-b";
import NotesC from "./components/notes-c";
import RegistrationFormA from "./components/registration-form-a";
import { Button, Tabs } from "antd";
import { FileSearchOutlined } from "@ant-design/icons";
import RegistrationFormB from "./components/registration-form-b";
import RegistrationFormC from "./components/registration-form-c";
import {ApartmentOutlined,AndroidOutlined} from '@ant-design/icons'

const RegistrationView = (props) => {
  const {
    isAgree,
    setIsAgree,
    handleClassificationChange,
    classification,
    category,
    setCategory,
  } = useContext(PageContext);
  return (
    <div className="flex flex-col h-screen items-center mb-56">
      <div className="flex flex-col items-center text-white bg-indigo-900 self-center w-full pb-5">
        <img
          src={require("../../assets/cebu-province-logo.jpg")}
          className="h-32 rounded-full"
        />
        <div>
          <p className="text-center text-2xl">QUARRY PASS REGISTRATION</p>
        </div>
        <p className="text-base">
          Check the status of your application here.{" "}
          <a
            href="/scan"
            className="text-blue-400 font-bold text-2xl underline"
          >
            Go to Scanner
          </a>
        </p>
      </div>
      <RegistrationFormA />
      <div className="">
        <p>Copyright @QUARRY Pass 2021</p>
      </div>
    </div>
  );
};

export default RegistrationView;
