import React, { useEffect, useState } from "react";
import { Input, Form, Button, notification, message } from "antd";
import { PageContext } from "../../lib/context";
import LandingView from "./view";

const LandingController = (props) => {

  const pageValues = {
  };

  return (
    <PageContext.Provider value={pageValues}>
      <LandingView />
    </PageContext.Provider>
  );
};

export default LandingController;
