import { ApolloClient, InMemoryCache, HttpLink } from 'apollo-boost'
import { WebSocketLink } from 'apollo-link-ws'
import { split } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'

const makeApolloClient = (token) => {
	// create an apollo link instance, a network interface for apollo client
	console.log('token',token)
	const httpLink = new HttpLink({
		uri: process.env.REACT_APP_HASURA_HTTPS,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})

	const wsLink = new WebSocketLink({
		uri: process.env.REACT_APP_HASURA_WSS,
		options: {
			reconnect: true,
			connectionParams: {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		},
	})

	const link = split(
		// split based on operation type
		({ query }) => {
			const { kind, operation } = getMainDefinition(query)
			return kind === 'OperationDefinition' && operation === 'subscription'
		},
		wsLink,
		httpLink
	)

	// create an inmemory cache instance for caching graphql data
	const cache = new InMemoryCache()

	// instantiate apollo client with apollo link instance and cache instance
	const client = new ApolloClient({
		link,
		cache,
		onError: (e) => {
			console.log('graphQLErrors', e)
		},
	})

	return client
}
export default makeApolloClient
