import { Button, Table } from "antd";
import React, { useContext } from "react";
import moment from "moment";
import { PageContext } from "../../../lib/context";
import _ from 'lodash'

const Information = () => {
  const { setCurrentView, vehicleData } = useContext(PageContext);

  const data = JSON.parse(vehicleData);

  const columns = [
    {
      title: "Application Type",
      render: (data) => <p>{data?.dealer_id ? 'Delivery' : 'Transfer'}</p>,
    },
    {
      title: "Date",
      key: "date",
      render: (data) => <p>{moment(data?.date).format("MMM-DD-YYYY")}</p>,
    },
    {
      title: "Status",
      key: "status",
      render: (data) => <p>{data?.status}</p>,
    },
  ];

  return (
    <div>
      <p className="font-bold">
        Company name:{" "}
        <span className="font-medium text-blue-500">
          {data?.vehicle_info?.company_name}
        </span>
      </p>
      <p className="font-bold">
        Company address:{" "}
        <span className="font-medium text-blue-500">
          {data?.vehicle_info?.company_address}
        </span>
      </p>
      <p className="font-bold mb-5">
        Status:{" "}
        <span className="font-medium text-green-400">
          {data?.vehicle_info?.status === "approved"
            ? "REGISTERED"
            : data?.vehicle_info?.status}
        </span>
      </p>
      <p className="font-bold self-center">Approved for Transfer or Delivery</p>
      <Table columns={columns} dataSource={_.orderBy(data?.pass_applications,"created_at",['desc'])} />
      {data?.vehicle_info?.status === "approved" && (
        <>
          <Button
            className="mt-5"
            type="primary bg-green-500 border-green-500"
            onClick={() => setCurrentView("apply-transfer")}
          >
            Apply Transfer
          </Button>
          <Button
            className="ml-5"
            type="primary bg-green-500 border-green-500"
            onClick={() => setCurrentView("apply-delivery")}
          >
            Apply Delivery
          </Button>
        </>
      )}
    </div>
  );
};

export default Information;
