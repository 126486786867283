import { Button, DatePicker, Form, Input, InputNumber, Select, Spin } from "antd";
import React, { useContext } from "react";
import { PageContext } from "../../../lib/context";
import moment from "moment";

const ApplyDelivery = () => {
  const { updateView, form, handleApply, logistics_data, applyingDelivery } = useContext(
    PageContext
  );

  return (
    <div>
      <p className="font-bold mb-3">Apply for Delivery</p>
      <Form
        form={form}
        onFinish={handleApply}
        className="w-full"
        layout="vertical"
      >
        <div className="flex flex-col">
          <Form.Item
            required
            rules={[{ required: true }]}
            label="Dealer"
            name="dealer_id"
          >
            <Select placeholder="Choose Dealer">
              {logistics_data?.dealer?.map((dealer) => (
                <Select.Option value={dealer.id}>
                  {dealer.company_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <p
            className="mb-3 text-blue-400 cursor-pointer"
            onClick={() => updateView("add-dealer")}
          >
            Can't find Dealer in list? Add here
          </p>
          <Form.Item
            required
            rules={[{ required: true }]}
            label="Logistics"
            name="logistics_id"
          >
            <Select placeholder="Choose Logistics / Trucking">
              {logistics_data?.logistics?.map((logistics) => (
                <Select.Option value={logistics.id}>
                  {logistics.company_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <p
            className="mb-3 text-blue-400 cursor-pointer"
            onClick={() => updateView("add-logistics")}
          >
            Can't find Logistics / Trucking in list? Add here
          </p>
          <Form.Item
            required
            rules={[{ required: true }]}
            label="Volume"
            name="volume"
          >
            <Input placeholder="Volume in Tons" />
          </Form.Item>
          <Form.Item
            required
            rules={[{ required: true }]}
            label="Date"
            name="date"
          >
            <DatePicker
              placeholder="Date of transfer"
              disabledDate={(current) => current && current < moment()}
            />
          </Form.Item>
          <Button
            disabled={applyingDelivery}
            htmlType="submit"
            type="primary"
            className="mb-5 bg-green-500 border-green-500"
          >
            {applyingDelivery ? <><Spin/> Applying</> : 'Submit'}
          </Button>
          <Button onClick={() => updateView("quarry-info")}>Cancel</Button>
        </div>
      </Form>
    </div>
  );
};

export default ApplyDelivery;
