import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Spin,
  Upload,
  Space
} from "antd";
import React, { useContext } from "react";
import { PageContext } from "../../../lib/context";
import moment from "moment";
import { UploadOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const AddLogistics = () => {
  const {
    updateView,
    form,
    handleAdd,
    handleImageUpload,
    loading,
  } = useContext(PageContext);

  return (
    <div>
      <p className="font-bold mb-3">Add Logistics</p>
      <Form
        form={form}
        onFinish={handleAdd}
        className="w-full"
        layout="vertical"
      >
        <div className="flex flex-col">
          <Form.Item
            name="company_name"
            label="Company name"
            required
            rules={[{ required: true }]}
          >
            <Input placeholder="Company name" />
          </Form.Item>
          <Form.Item
            name="company_address"
            label="Company address"
            required
            rules={[{ required: true }]}
          >
            <Input placeholder="Company address" />
          </Form.Item>
          <Form.Item
            name="contact_person"
            label="Contact person"
            required
            rules={[{ required: true }]}
          >
            <Input placeholder="Contact person" />
          </Form.Item>
          <Form.List name="items">
            {(fields, { add, remove }) => {
              return (
                <div className="flex flex-col">
                  {fields.map((field) => (
                    <Space
                      key={field.key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="start"
                    >
                      <Form.Item
                        {...field}
                        name={[field.name, "plate_no"]}
                        fieldKey={[field.fieldKey, "plate_no"]}
                        rules={[{ required: true, message: "Missing plate no." }]}
                      >
                        <Input placeholder="Plate no" />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, "truck_type"]}
                        fieldKey={[field.fieldKey, "truck_type"]}
                        rules={[{ required: true, message: "Truck type" }]}
                      >
                        <Input placeholder="Truck type" />
                      </Form.Item>
                      <MinusCircleOutlined
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </Space>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined /> Add Truck
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List> 
          <Upload
            className="self-start mb-5"
            beforeUpload={(file) => handleImageUpload("business_permit", file)}
            accept=".png,.jpg,.jpeg"
          >
            <Button icon={<UploadOutlined />}>
              Upload Business Permit Picture
            </Button>
          </Upload>
          <Button
            disabled={loading}
            htmlType="submit"
            type="primary"
            className="mb-5 bg-green-500 border-green-500"
          >
            {loading ? (
              <>
                <Spin /> Registering logistics 
              </>
            ) : (
              "Submit"
            )}
          </Button>
          <Button onClick={() => updateView("quarry-info")}>Cancel</Button>
        </div>
      </Form>
    </div>
  );
};

export default AddLogistics;
