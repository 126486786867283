import _ from "lodash";
import axios from "axios";

export const formatMoney = (x) => {
  return x?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const uploadFile = (file) => {
  let formData = new FormData();
  formData.append("file", file);
  return axios
    .post("https://vehicle-api.wetrace.ph/upload", formData, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_DEFAULT_JWT}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};
