import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Spin,
  Upload,
} from "antd";
import React, { useContext } from "react";
import { PageContext } from "../../../lib/context";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";

const AddProcessor = () => {
  const {
    updateView,
    form,
    handleAdd,
    handleImageUpload,
    loading,
  } = useContext(PageContext);

  return (
    <div>
      <p className="font-bold mb-3">Add Processor</p>
      <Form
        form={form}
        onFinish={handleAdd}
        className="w-full"
        layout="vertical"
      >
        <div className="flex flex-col">
          <Form.Item
            name="company_name"
            label="Company name"
            required
            rules={[{ required: true }]}
          >
            <Input placeholder="Company name" />
          </Form.Item>
          <Form.Item
            name="company_address"
            label="Plant address"
            required
            rules={[{ required: true }]}
          >
            <Input placeholder="Plant address" />
          </Form.Item>
          <Form.Item
            name="contact_person"
            label="Contact person"
            required
            rules={[{ required: true }]}
          >
            <Input placeholder="Contact person" />
          </Form.Item>
          <Form.Item
            name="type_of_minerals"
            label="Type of minerals"
            required
            rules={[{ required: true }]}
          >
            <Input placeholder="sand, clay, stone" />
          </Form.Item>
          <Upload
            className="self-start mb-5"
            beforeUpload={(file) => handleImageUpload("business_permit", file)}
            accept=".png,.jpg,.jpeg"
          >
            <Button icon={<UploadOutlined />}>
              Upload Business Permit Picture
            </Button>
          </Upload>
          <Button
            disabled={loading}
            htmlType="submit"
            type="primary"
            className="mb-5 bg-green-500 border-green-500"
          >
            {loading ? (
              <>
                <Spin /> Registering processor
              </>
            ) : (
              "Submit"
            )}
          </Button>
          <Button onClick={() => updateView("quarry-info")}>Cancel</Button>
        </div>
      </Form>
    </div>
  );
};

export default AddProcessor;
