import React from 'react'
import { QRCode } from "react-qrcode-logo";
import _ from 'lodash'

const ComponentToPrint = React.forwardRef((props, ref) => (
  <div ref={ref}>
    <div
      className="text-gray-700"
      style={{
        padding: 10,
        display: "flex",
        flexDirection: "row",
        width: 700,
        flexWrap: "wrap",
        textAlign: "left",
        alignItems: "center",
      }}
    >
      {props.selected.map((id, key) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop:
              (key % 12 === 0 || (key % 12 === 1 && key > 12)) && key !== 0
                ? 130
                : 10,
            // width: "50%",
          }}
        >
          <div
            style={{
              backgroundImage: `url(${require("../../../assets/new-pass.png")})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              height: 450,
              width: 700,
              // marginLeft: 50,
              objectFit: "fill",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                height: 71,
                width: 70,
                right: 203,
                top: 164,
              }}
            >
              <QRCode
                value={id?.id}
                size={120}
                logoWidth={20}
                logoHeight={20}
                logoImage={require("../../../assets/cebu-province-logo.jpg")}
              />
            </div>
            <p
              style={{
                position: "relative",
                left: 24,
                top: 344,
                fontSize: 13,
              }}
            >{`CPG-LSTP ${_.padStart(id?.control_no?.toString(), 3, "00")}`}</p>
            <p
              style={{
                position: "relative",
                left: 154,
                top: 323,
                fontSize: 13,
              }}
            >{`${id?.company_name}`}</p>
            <p
              style={{
                position: "relative",
                left: 30,
                top: 368,
                fontSize: 13,
              }}
            >{`${id?.plate_no?.toUpperCase()}`}</p>
            <p
              style={{
                position: "relative",
                left: 145,
                top: 350,
                fontSize: 13,
              }}
            >{`${id?.region?.toUpperCase()}`}</p>
            <p
              style={{
                position: "relative",
                left: 252,
                top: 330,
                fontSize: 13,
              }}
            >{`${id?.province?.toUpperCase()}`}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
));

export default ComponentToPrint