import React, { useEffect, useState } from "react";
import { Input, Form, Button, notification, message } from "antd";
import RegistrationView from "./view";
import { PageContext } from "../../lib/context";
import {
  INSERT_BUSINESS_CERTIFICATES_IMAGES,
  INSERT_TRUCK_BOYS,
  INSERT_VEHICLES,
  UPSERT_USERS,
  UPSERT_USER_BUSINESS_CERTIFICATES,
  UPSERT_USER_ITEMS,
} from "../../lib/mutations";
import { useMutation } from "@apollo/react-hooks";
import S3 from "react-aws-s3";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { uploadFile } from "../../lib/util";

const Registration = (props) => {
  const [form] = Form.useForm();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [tbimages, setTbImages] = useState([]);
  const [registrationId, setRegistrationId] = useState("");
  const [visible, setVisible] = useState(false);

  const [addVehicle] = useMutation(INSERT_VEHICLES);
  const [addTruckBoys] = useMutation(INSERT_TRUCK_BOYS);

  const handleFinishForm = async (data) => {
    // console.log(data)
    setLoading(true);
    if (images.length < 1) {
      notification.error({
        message: "Must upload all required photos",
      });
      setLoading(false);
      return;
    }

    const image_locations = [];
    for (var i = 0; i < images.length; i++) {
      let item = images[i];
      const { data: user_picture } = await uploadFile(item.file);
      image_locations.push({
        key: item.key,
        user_picture,
      });
    }

    // console.log(image_locations)
    const payloadVehicle = { ...data };
    delete payloadVehicle.items;
    payloadVehicle["business_permit"] = image_locations.filter(
      (i) => i.key === "business_permit"
    )?.[0]?.user_picture;
    const res = await addVehicle({
      variables: {
        object: [
          {
            ...payloadVehicle,
          },
        ],
      },
    });
    const id = res?.data?.insert_vehicles?.returning?.[0]?.id;
    if (id) {
      notification.success({
        message: "Registration sent.",
        description:
          "You have successfully registered and is now pending for review.",
      });
      setRegistrationId(id);
      setVisible(true);
    } else {
      notification.error({
        message: "Registration failed.",
        description:
          "Something went wrong while registering vehicle. error [01]",
      });
    }
    setLoading(false);
  };

  const handleImageUpload = (key, file) => {
    setImages([...images, { key, file }]);
  };

  const handleTruckBoyImages = (name, file) => {
    console.log(name);
    setTbImages([...tbimages, { key: name, file }]);
  };

  const handleDeleteTruckboyImage = (name) => {
    const removed = tbimages.filter((i) => i.key !== name);
    setTbImages([...removed]);
  };

  const handleReset = () => {
    setRegistrationId("");
    setVisible(false);
    form.resetFields();
  };

  const pageValues = {
    form,
    handleFinishForm,
    loading,
    handleImageUpload,
    handleTruckBoyImages,
    handleDeleteTruckboyImage,
    registrationId,
    visible,
    handleReset,
  };

  return (
    <PageContext.Provider value={pageValues}>
      <RegistrationView />
    </PageContext.Provider>
  );
};

export default Registration;
