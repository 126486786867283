import React, { useCallback, useEffect, useState } from "react";
import { Input, Form, Button, notification, message } from "antd";
import ScanView from "./view";
import { PageContext } from "../../lib/context";
import { useMutation, useQuery, useSubscription } from "@apollo/react-hooks";
import {
  APPLY_DELIVERY,
  APPLY_PASS,
  APPLY_TRANSFER,
  INSERT_TRUCK_BOYS,
  INSERT_VEHICLES,
  SCAN_QR,
} from "../../lib/mutations";
import moment from "moment";
import { REGISTERED_DATA } from "../../lib/subscriptions";
import { uploadFile } from "../../lib/util";

const ScanController = (props) => {
  const [showScanner, setShowScanner] = useState(false);
  const [vehicleData, setVehicleData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentView, setCurrentView] = useState("quarry-info"); // (default) quarry-info (other values) apply-transfer, apply-delivery, add-processor, add-logistics, add-dealer

  const [form] = Form.useForm();

  const [scanQR, { loading: scanning }] = useMutation(SCAN_QR);
  const [addVehicle] = useMutation(INSERT_VEHICLES);
  const [addTruckBoys] = useMutation(INSERT_TRUCK_BOYS);
  const [applyTransfer, { loading: applyingTransfer }] = useMutation(
    APPLY_TRANSFER
  );
  const [applyDelivery, { loading: applyingDelivery }] = useMutation(
    APPLY_DELIVERY
  );
  const { data: logistics_data } = useQuery(REGISTERED_DATA, {
    pollInterval: 3000,
  });

  const updateView = useCallback(
    (view) => {
      setCurrentView(view);
    },
    [setCurrentView]
  );

  const handleScan = (id) => {
    if (id) {
      setVehicleData(null);
      setShowScanner(false);
      async function scan() {
        let res = await scanQR({
          variables: {
            id,
          },
        });
        if (res?.data?.scan_qr?.code === 200) {
          setVehicleData(res?.data?.scan_qr?.data);
        }
      }
      scan(id);
    }
  };

  // useEffect(()=>{
  //   handleScan('c12c8762-28cf-4ad6-a9cc-cc376f1b7e2b')
  // },[])

  const handleError = (err) => {
    console.error(err);
    alert(err);
  };

  const handleImageUpload = (key, file) => {
    setImages([...images, { key, file }]);
  };

  const handleApply = async (data) => {
    const vehicle_data = JSON.parse(vehicleData);
    const key =
      currentView === "apply-transfer"
        ? "insert_transfer_applications"
        : "insert_delivery_applications";
    const applied_application =
      currentView === "apply-transfer" ? "Transfer" : "Delivery";
    let apply;
    apply =
      currentView === "apply-transfer"
        ? await applyTransfer({
            variables: {
              data: [{ ...data, company_id: vehicle_data?.vehicle_info?.id }],
            },
          })
        : await applyDelivery({
            variables: {
              data: [{ ...data, company_id: vehicle_data?.vehicle_info?.id }],
            },
          });
    if (apply?.data?.[key]?.affected_rows) {
      notification.success({
        message: `Successfully applied for ${applied_application}`,
      });
      handleScan(vehicle_data?.vehicle_info?.id);
      updateView("quarry-info");
      form.resetFields();
    }
  };

  const handleAdd = async (data) => {
    setLoading(true);
    if (images.length < 1) {
      notification.error({
        message: "Must upload all required photos",
      });
      setLoading(false);
      return;
    }

    if (currentView === "add-logistics") {
      if (!data?.items?.length) {
        notification.error({
          message: "Must list down all trucks",
        });
        setLoading(false);
        return;
      }
    }

    const type =
      currentView === "add-processor"
        ? "PROCESSOR"
        : currentView === "add-logistics"
        ? "LOGISTICS"
        : "DEALER";

    const image_locations = [];
    for (var i = 0; i < images.length; i++) {
      let item = images[i];
      const { data: user_picture } = await uploadFile(item.file);
      image_locations.push({
        key: item.key,
        user_picture,
      });
    }

    const payloadVehicle = { ...data };
    delete payloadVehicle.items;
    payloadVehicle["business_permit"] = image_locations.filter(
      (i) => i.key === "business_permit"
    )?.[0]?.user_picture;
    const res = await addVehicle({
      variables: {
        object: [
          {
            ...payloadVehicle,
            type,
          },
        ],
      },
    });
    const id = res?.data?.insert_vehicles?.returning?.[0]?.id;
    if (id) {
      if (currentView === "add-logistics") {
        // if add logistics must add trucks
        const truckboysPayload = [];
        data.items.map((tb, key) => {
          truckboysPayload.push({
            plate_no: tb.plate_no,
            truck_type: tb.truck_type,
            vehicle_id: id,
          });
        });
        const insert_tb = await addTruckBoys({
          variables: {
            object: [...truckboysPayload],
          },
        });
        const affected_rows = insert_tb?.data?.insert_truck_boys?.affected_rows;
        if (affected_rows > 0) {
          notification.success({
            message: "Registered " + type,
            description: "You have successfully registered " + type,
          });
          setLoading(false);
          updateView("quarry-info");
          form.resetFields();
        } else {
          notification.error({
            message: "Registration failed.",
            description:
              "Something went wrong while registering vehicle. error [02]",
          });
        }
      } else {
        // notif if not add logistics
        notification.success({
          message: "Registered " + type,
          description: "You have successfully registered " + type,
        });
        setLoading(false);
        updateView("quarry-info");
        form.resetFields();
      }
    } else {
      notification.error({
        message: "Registration failed.",
        description: "Something went wrong while registering. error [01]",
      });
    }
    setLoading(false);
  };

  const pageValues = {
    handleScan,
    handleError,
    showScanner,
    setShowScanner,
    vehicleData,
    scanning,
    setVehicleData,
    setVisible,
    visible,
    form,
    handleImageUpload,
    loading,
    currentView,
    setCurrentView,
    updateView,
    logistics_data,
    handleApply,
    handleAdd,
    applyingDelivery,
    applyingTransfer,
  };

  return (
    <PageContext.Provider value={pageValues}>
      <ScanView />
    </PageContext.Provider>
  );
};

export default ScanController;
