import React, { useRef, useState } from "react";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import { Button, DatePicker, Form, Modal, Spin, Table, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import QrReader from "react-qr-reader";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { QRCode } from "react-qrcode-logo";
import _ from "lodash";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG,
} from "react-component-export-image";
import QuarryInfo from "./components/quarry-info";

const ScanView = (props) => {

  return (
    <div className="flex flex-col h-screen items-center mb-56">
      <div className="flex flex-col items-center self-center w-2/5">
        <img
          src={require("../../assets/cebu-province-logo.jpg")}
          className="h-32 rounded-lg"
        />
        <div>
          <p className="text-center text-sm lg:text-2xl">
            QUARRY PASS QR Code Scanner
          </p>
        </div>
      </div>
      <p className="text-base mb-10">
        To apply for a new vehicle pass{" "}
        <a
          href="/registration"
          className="text-blue-400 font-bold text-base underline"
        >
          Go to Registration page
        </a>
      </p>
      <p className="mb-5 font-medium">Scan your QR Code to proceed</p>
      <p className="mb-5">
        NOTE: For iOS/iPhone users please use Safari Brower in order for the
        scanner to work.
      </p>
      <div>
        <QuarryInfo/>
      </div>
    </div>
  );
};

export default ScanView;
