import React, { useRef, useState } from "react";
import { useContext } from "react";
import { PageContext } from "../../../lib/context";
import { Button, DatePicker, Form, Modal, Spin, Table, Upload } from "antd";
import QrReader from "react-qr-reader";
import moment from "moment";
import _ from "lodash";
import { exportComponentAsPNG } from "react-component-export-image";
import ModalComponent from "./modal";
import ComponentToPrint from "./component-to-print";
import Information from "./information";
import ApplyTransfer from "./apply-transfer";
import ApplyDelivery from "./apply-delivery";
import AddProcessor from "./add-processor";
import AddLogistics from "./add-logistics";
import AddDealer from "./add-dealer";

const QuarryInfo = (props) => {
  const {
    handleError,
    handleScan,
    showScanner,
    setShowScanner,
    vehicleData,
    scanning,
    setVehicleData,
    setVisible,
    currentView,
    setCurrentView,
    updateView,
  } = useContext(PageContext);

  const componentRefs = useRef();

  const renderDetails = () => {
    if (vehicleData !== "") {
      return (
        <div className="mb-5 flex flex-col items-start">
          {currentView === "quarry-info" && <Information />}
          {currentView === "apply-transfer" && <ApplyTransfer />}
          {currentView === "apply-delivery" && <ApplyDelivery />}
          {currentView === "add-processor" && <AddProcessor />}
          {currentView === "add-logistics" && <AddLogistics />}
          {currentView === "add-dealer" && <AddDealer />}
        </div>
      );
    }
  };

  return (
    <div className="flex flex-col h-screen items-center mb-56">
      <div>
        {/* checker loading state */}
        {scanning && (
          <div>
            <Spin />
            <p>Checking QR Code...</p>
          </div>
        )}

        {/* Qrcode reader */}
        {showScanner && !scanning && (
          <QrReader
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: 200, height: 200 }}
          />
        )}
        {vehicleData !== null && !scanning && renderDetails()}
      </div>

      {/* Show scanner button if not scanning  */}
      {!showScanner && !scanning && (
        <Button
          type="primary"
          onClick={() => {
            setVehicleData(null);
            setShowScanner(true);
          }}
        >
          Show Scanner
        </Button>
      )}

      {/* show Export to png of pass if not scanning*/}
      {vehicleData !== null && !scanning && (
        <>
          {/* <Button
            className="bg-green-400 border-green-400 mt-5"
            type="primary"
            onClick={() => exportComponentAsPNG(componentRefs)}
          >
            Export Pass as PNG
          </Button>
          <p className="my-3">
            Tilt your phone into landscape mode to view the whole image.
          </p> */}
          {/* <ComponentToPrint
            ref={componentRefs}
            selected={[JSON.parse(vehicleData)?.vehicle_info]}
          /> */}
        </>
      )}

      {/* pass application modal  */}
      <ModalComponent />

      {/* footer */}
      <div className="mt-56">
        <p>Copyright @QUARRY Pass 2021</p>
      </div>
    </div>
  );
};

export default QuarryInfo;
