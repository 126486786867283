import gql from "graphql-tag";

export const REGISTERED_DATA = gql`
query MySubscription {
  processors: vehicles(where: {type: {_eq: "PROCESSOR"}}) {
    id
    company_name
  }
  logistics: vehicles(where: {type: {_eq: "LOGISTICS"}}) {
    id
    company_name
  }
  dealer: vehicles(where: {type: {_eq: "DEALER"}}) {
    id
    company_name
  }
}
`