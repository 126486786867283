import React, { useContext } from 'react'
import { PageContext } from '../../../lib/context';
import { Button, DatePicker, Form, Modal, Spin, Table, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import _ from 'lodash'

const ModalComponent = () => {
  const {
    setVisible,
    visible,
    handlePassApplication,
    form,
    handleImageUpload,
    loading,
  } = useContext(PageContext);
  
  return (
    <Modal
        title={"Apply for pass"}
        visible={visible}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={() => setVisible(false)}
      >
        <div>
          <p>Input Date Ranges</p>
          <Form form={form} onFinish={handlePassApplication}>
            <Form.Item
              name="date_range"
              rules={[{ required: true, message: "Must selecte date range" }]}
            >
              <DatePicker.RangePicker />
            </Form.Item>
            <Upload
              className="self-start mb-2"
              beforeUpload={(file) =>
                handleImageUpload("notice_of_compliance", file)
              }
              accept=".png,.jpg,.jpeg"
            >
              <Button icon={<UploadOutlined />}>
                Upload Notice of Compliance
              </Button>
            </Upload>
            <Upload
              className="self-start mb-2"
              beforeUpload={(file) =>
                handleImageUpload("certificate_of_downtime", file)
              }
              accept=".png,.jpg,.jpeg"
            >
              <Button icon={<UploadOutlined />}>
                Upload Certificate of downtime
              </Button>
            </Upload>
            <Button
              type="primary"
              htmlType="submit"
              className="mt-5"
              disabled={loading}
            >
              {loading ? (
                <>
                  <Spin />
                  Submitting..
                </>
              ) : (
                "Submit application"
              )}
            </Button>
          </Form>
        </div>
      </Modal>
  )
}

export default ModalComponent