import React, { useContext, useEffect, useMemo, useRef } from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Radio,
  Checkbox,
  Button,
  Space,
  Upload,
  notification,
  Spin,
  Modal,
} from "antd";
import {
  UserOutlined,
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { PageContext } from "../../../lib/context";
import { Offices } from "./offices";
import locations from "./locations.json";
import { QRCode } from "react-qrcode-logo";
// import QRCode from 'qrcode.react'

const RegistrationFormA = (props) => {
  const {
    handleFinishForm,
    loading,
    form,
    handleImageUpload,
    handleTruckBoyImages,
    handleDeleteTruckboyImage,
    visible,
    registrationId,
    handleReset,
    setVisible,
  } = useContext(PageContext);

  const [regions, setRegions] = useState([]); // permanent
  const [selectedRegion, setSelectedRegion] = useState(""); // permanent
  const [provinces, setProvinces] = useState([]); // permanent
  const [selectedProvince, setSelectedProvince] = useState(""); // permanent
  const [currentRegions, setCurrentRegions] = useState([]); // current

  let downloadRef = useRef();

  const download = () => {
    const canvas = document.querySelector(".HpQrcode > canvas");
    downloadRef.href = canvas.toDataURL();
    downloadRef.download = "QrCode-vehicle-pass.png";
  };

  useEffect(() => {
    let regions = [];
    Object.keys(locations).map((key, index) => {
      console.log(key);
      regions = [
        ...regions,
        { key: key.toString(), value: locations[key].region_name },
      ];
    });
    setRegions(regions);
    setCurrentRegions(regions);
  }, []);

  useEffect(() => {
    if (visible) {
      download();
    }
  }, [visible]);

  const required = (name) => {
    return [
      {
        required: true,
        message: `${name} is required`,
      },
    ];
  };

  const handleFinishFailed = () => {
    notification.error({
      message: "You have missed some fields.",
      description: "Fields with * are required.",
    });
  };

  function onRegionChange(regionValue) {
    setSelectedRegion(regionValue);
    let provinces = [];
    Object.keys(locations[regionValue].province_list).map((key, index) => {
      provinces = [...provinces, { key: key, value: key }];
    });
    setProvinces(provinces);
    let reset = form.setFieldsValue({
      province: null,
    });
  }

  function onProvinceChange(provinceValue) {
    setSelectedProvince(provinceValue);
  }

  return (
    <div className="w-full lg:w-3/5 m-5">
      {!visible && (
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinishForm}
          onFinishFailed={handleFinishFailed}
        >
          <div className="flex">
            <p className="font-bold self-start">Company Information</p>
          </div>
          <div className="flex flex-col lg:flex-row w-full">
            <Form.Item
              required
              rules={required("Company name")}
              label="Company name"
              name="company_name"
              className="w-full mr-5"
            >
              <Input placeholder="SM Cebu" prefix={<UserOutlined />} />
            </Form.Item>
            <Form.Item
              required
              rules={required("Company address")}
              label="Company address"
              name="company_address"
              className="w-full mr-5"
            >
              <Input placeholder="Cebu City" prefix={<UserOutlined />} />
            </Form.Item>
          </div>
          <div className="flex flex-col lg:flex-row w-full">
            <Form.Item
              name={"region"}
              label={"Region"}
              rules={[{ required: true, message: "Region is required" }]}
              className="w-full mr-5"
            >
              <Select
                showSearch
                placeholder="Select a region"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(e) => onRegionChange(e, null)}
              >
                {regions.map((region, key) => {
                  return (
                    <Select.Option value={region.key}>
                      {region.value}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name={"province"}
              label={"Province"}
              rules={[{ required: true, message: "Province is required" }]}
              className="w-full mr-5"
            >
              <Select
                disabled={provinces.length ? false : true}
                showSearch
                placeholder="Select a province"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(e) => onProvinceChange(e, null)}
              >
                {provinces.map((province, key) => {
                  return (
                    <Select.Option value={province.key}>
                      {province.value}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <div className="flex">
            <p className="font-bold self-start">Contact Details</p>
          </div>
          <div className="flex flex-col lg:flex-row w-full">
            <Form.Item
              required
              rules={required("Contact person")}
              label="Contact person"
              name="contact_person"
              className="w-full mr-5"
            >
              <Input placeholder="Juan Dela cruz" prefix={<UserOutlined />} />
            </Form.Item>
            <Form.Item
              required
              rules={required("Contact person number")}
              label="Contact person number"
              name="contact_person_number"
              className="w-full mr-5"
            >
              <Input
                className="w-full"
                minLength={11}
                maxLength={11}
                placeholder="09XXX.."
                prefix={<UserOutlined />}
              />
            </Form.Item>
            <Form.Item
              required
              rules={required("Office contact number")}
              label="Office contact number"
              name="office_contact_number"
              className="w-full mr-5"
            >
              <Input
                className="w-full"
                minLength={11}
                maxLength={11}
                placeholder="09XXX.."
                prefix={<UserOutlined />}
              />
            </Form.Item>
          </div>

          <div className="flex mb-5">
            <p className="font-bold self-start">Company Permits</p>
          </div>
          <div className="mb-5 flex flex-col">
            <Upload
              className="self-start mb-5"
              beforeUpload={(file) =>
                handleImageUpload("business_permit", file)
              }
              accept=".png,.jpg,.jpeg"
            >
              <Button icon={<UploadOutlined />}>
                Upload Business Permit Picture
              </Button>
            </Upload>
            {/* <Form.Item
              required
              rules={required("Driver 1 Name")}
              label="Driver 1 Name"
              name="drivers_name"
              className="w-full mr-5"
            >
              <Input placeholder="Juan Dela cruz" prefix={<UserOutlined />} />
            </Form.Item> */}
            {/* <Upload
              className="self-start"
              beforeUpload={(file) =>
                handleImageUpload("drivers_license", file)
              }
              accept=".png,.jpg,.jpeg"
            >
              <Button icon={<UploadOutlined />}>
                Upload Driver 1 License Picture
              </Button>
            </Upload>
            <Upload
              className="self-start mt-2"
              beforeUpload={(file) =>
                handleImageUpload("drivers_picture", file)
              }
              accept=".png,.jpg,.jpeg"
            >
              <Button icon={<UploadOutlined />}>Upload Driver 1 Picture</Button>
            </Upload>
            <Form.Item
              required
              label="Driver 2 Name"
              name="drivers_name_2"
              className="w-full mr-5 mt-5"
            >
              <Input placeholder="Juan Dela cruz" prefix={<UserOutlined />} />
            </Form.Item>
            <Upload
              className="self-start"
              beforeUpload={(file) =>
                handleImageUpload("drivers_license_2", file)
              }
              accept=".png,.jpg,.jpeg"
            >
              <Button icon={<UploadOutlined />}>
                Upload Driver 2 License Picture
              </Button>
            </Upload>
            <Upload
              className="self-start mt-2"
              beforeUpload={(file) =>
                handleImageUpload("drivers_picture_2", file)
              }
              accept=".png,.jpg,.jpeg"
            >
              <Button icon={<UploadOutlined />}>Upload Driver 2 Picture</Button>
            </Upload> */}
          </div>

          <div className="flex mb-5">
            <p className="font-bold self-start">Quarry Information</p>
          </div>
          <div className="mb-5 flex flex-row">
            <Form.Item
              required
              rules={required("Total Area")}
              label="Total Area"
              name="total_area"
              className="w-full mr-5"
            >
              <Input
                className="w-full"
                placeholder="2 hectares"
              />
            </Form.Item>
            <Form.Item
              required
              rules={required("Volume applied")}
              label="Volume Applied in Tons"
              name="volume_applied"
              className="w-full mr-5"
            >
              <InputNumber
                className="w-full"
                placeholder="10000"
              />
            </Form.Item>
          </div>
          <div className="mb-5 flex flex-row">
            <Form.Item
              required
              rules={required("Quarry Location")}
              label="Quarry Location"
              name="quarry_location"
              className="w-full mr-5"
            >
              <Input
                className="w-full"
                placeholder="Naga"
              />
            </Form.Item>
            <Form.Item
              required
              rules={required("Types of Minerals")}
              label="Types of Minerals (separated by comma ,)"
              name="type_of_minerals"
              className="w-full mr-5"
            >
              <Input
                className="w-full"
                placeholder="stone, sand, clay"
              />
            </Form.Item>
          </div>
          {/* <div className="flex">
            <p className="font-bold self-start">Vehicle Information</p>
          </div>
          <div className="flex flex-col w-full">
            <Form.Item
              required
              rules={required("Truck Make")}
              label="Truck Make"
              name="truck_make"
              className="w-full mr-5"
            >
              <Input placeholder="Isuzu, Suzuki" prefix={<UserOutlined />} />
            </Form.Item>
            <Form.Item
              required
              rules={required("Plate No.")}
              label="Plate No."
              name="plate_no"
              className="w-full mr-5"
            >
              <Input placeholder="plate no." prefix={<UserOutlined />} />
            </Form.Item>
            <Upload
              className="self-start"
              beforeUpload={(file) =>
                handleImageUpload("truck_picture_front", file)
              }
              accept=".png,.jpg,.jpeg"
            >
              <Button icon={<UploadOutlined />}>
                Upload Truck Front "Plate" Picture
              </Button>
            </Upload>
            <Upload
              className="self-start mt-2"
              beforeUpload={(file) =>
                handleImageUpload("truck_picture_side", file)
              }
              accept=".png,.jpg,.jpeg"
            >
              <Button icon={<UploadOutlined />}>
                Upload Truck Side View full Picture
              </Button>
            </Upload>
            <Upload
              className="self-start mt-2"
              beforeUpload={(file) => handleImageUpload("truck_or", file)}
              accept=".png,.jpg,.jpeg"
            >
              <Button icon={<UploadOutlined />}>
                Upload Truck O/R Picture
              </Button>
            </Upload>
            <Upload
              className="self-start mt-2"
              beforeUpload={(file) => handleImageUpload("truck_cr", file)}
              accept=".png,.jpg,.jpeg"
            >
              <Button icon={<UploadOutlined />}>
                Upload Truck C/R Picture
              </Button>
            </Upload>
          </div> */}

          {/* <p className="font-bold">Truck boys details</p>
          <Form.List name="items">
            {(fields, { add, remove }) => {
              return (
                <div className="flex flex-col">
                  {fields.map((field) => (
                    <Space
                      key={field.key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="start"
                    >
                      <Form.Item
                        {...field}
                        name={[field.name, "name"]}
                        fieldKey={[field.fieldKey, "name"]}
                        rules={[{ required: true, message: "Missing name" }]}
                      >
                        <Input placeholder="Name" />
                      </Form.Item>
                      <Upload
                        className="self-start"
                        beforeUpload={(file) =>
                          handleTruckBoyImages(field.fieldKey, file)
                        }
                        accept=".png,.jpg,.jpeg"
                      >
                        <Button icon={<UploadOutlined />}>
                          Upload Truck boy Picture
                        </Button>
                      </Upload>
                      <MinusCircleOutlined
                        onClick={() => {
                          remove(field.name);
                          handleDeleteTruckboyImage(field.fieldKey);
                        }}
                      />
                    </Space>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined /> Add Truck boy
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List> */}

          {/* <div className="flex">
            <p className="font-bold self-start">Other Information</p>
          </div>
          <div className="flex flex-col w-full">
            <Upload
              className="self-start"
              beforeUpload={(file) =>
                handleImageUpload("handlers_permit", file)
              }
              accept=".png,.jpg,.jpeg"
            >
              <Button icon={<UploadOutlined />}>Handlers Permit Picture</Button>
            </Upload>
            <Upload
              className="self-start mt-3"
              beforeUpload={(file) =>
                handleImageUpload("transport_carrier_permit", file)
              }
              accept=".png,.jpg,.jpeg"
            >
              <Button icon={<UploadOutlined />}>
                Transport Carrier Permit Picture
              </Button>
            </Upload>
          </div> */}

          <Button
            htmlType="submit"
            type="primary"
            className="text-center mt-10"
            disabled={loading}
          >
            {loading ? (
              <>
                <Spin /> Registering... please wait
              </>
            ) : (
              "Submit Registration"
            )}
          </Button>
        </Form>
      )}

      {visible && (
        <>
          <div className="HpQrcode self-center items-center flex justify-center">
            <QRCode
              value={registrationId}
              logoWidth={40}
              logoHeight={40}
              logoImage={require("../../../assets/cebu-province-logo.jpg")}
              size={250}
            />
          </div>
          <p className="font-medium">
            Congratulations you have successfully registered.
          </p>
          <p className="font-medium">
            Your application will now be reviewed for approval.
          </p>
          <br />
          <p className="font-medium">
            Download your QRCODE by clicking the button below.
          </p>
          <br />
          <a
            className="bg-blue-400 p-2 text-white rounded-lg hover:text-yellow-400"
            ref={(refs) => (downloadRef = refs)}
          >
            Download QR Code
          </a>
          <br />
          <br />
          <p className="font-medium">
            Use this to check the status of your application and to apply for a
            new pass.
          </p>
          <br />
          <br />
          <a
            onClick={handleReset}
            className="bg-green-400 p-2 text-white rounded-lg hover:text-yellow-400"
          >
            Click here to apply for another one
          </a>
        </>
      )}
    </div>
  );
};

export default RegistrationFormA;
