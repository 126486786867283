import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { authGuard, AuthGuard, DashGuard } from "./modules";
import { ApolloProvider } from "@apollo/react-hooks";
import { AppContext } from "../lib/context";
import makeApolloClient from "../lib/apollo";
import Registration from "../pages/registration";
import ScanController from "../pages/scan";
import LandingController from "../pages/landing";

export default function App() {
  const [client, setClient] = useState(null);

  const { TOKEN } = useContext(AppContext);

  const initClient = async () => {
    let client
    const token = localStorage.getItem(process.env.REACT_APP_LS_TOKEN)
    if (token) {
      client = makeApolloClient(token);
    } else {
      client = makeApolloClient(process.env.REACT_APP_DEFAULT_JWT);
    }
    setClient(client);
  };

  useEffect(() => {
    initClient();
  }, []);

  if (!client) return null

  return (
    <Router>
      <ApolloProvider client={client}>
        <Switch>
          <AuthGuard exact path="/registration" component={Registration}/>
          <AuthGuard exact path="/scan" component={ScanController}/>
          <AuthGuard exact path="/" component={LandingController}/>
        </Switch>
      </ApolloProvider>
    </Router>
  );
}
